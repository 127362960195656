import { React } from 'Imports';
import { VPDateRange } from '$Components/FilterComponents/VPDateRange';
import { VPDropdown } from '$Components/FilterComponents/VPDropdown';
import { IDropdownItem } from '$Components/FilterComponents/VPDropdown';
import { VideoEventTypeResponse, VideoEventWorkflowStatusIdEnum, VideoEventFilterMultiselectRequest } from '$Generated/api';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { VideoEventService, IVideoEventServiceInjectedProps } from '$State/VideoEventFreezerService';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
import { FormControl, FormLabel, ToggleButton, styled } from 'MaterialUIComponents';
import { getVideoEventStatusDropdownList } from '$Utilities/videoEventStatusUtility';
import { DriverDropdownMultiselect } from '$Components/Shared/DriverDropdownMultiselect';
import { VehicleDropdownMultiselect } from '$Components/Shared/VehicleDropdownMultiselect';

import * as scssStyles from '$CSS/settings.scss';
import { GetImageUrl, SortDropdownItems } from '$Utilities/dataModelUtilities';
import { toggleButtonClasses } from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as _ from 'lodash';

interface IMultiSelectFilterBarProps {
    hideEventType?: boolean;
    hideDrivers?: boolean;
    onFilterOptionsChange: (value: IMultiselectFilterOptions) => void;
    eventFilter: VideoEventFilterMultiselectRequest;
    isMobileView: boolean;
    isVideoRecallPage: boolean;
    hideFlaggedVideosControl: boolean;
    includeInactiveDrivers?: boolean;
    onChangeIncludeInactiveDrivers: (includeInactives?: boolean) => void;
    includeInactiveVehicles?: boolean;
    onChangeIncludeInactiveVehicles: (includeInactives?: boolean) => void;
}

const styles = require('$Components/FilterComponents/MultiSelectFilterBar.scss') as {
    main: string;
    mainMobile: string;
    filter: string;
    switchLabel: string;
    hidden: string;
    flaggedSwitch: string;
    flaggedToggleContainer: string;
    flaggedToggleLabel: string;
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonClasses.root}`]: {
        fontSize: '10px',
        padding: '9px',
        height: '25px',
        color: '#989898',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '50px',
        borderColor: '#989898',
        textTransform: 'none',
        '&:first-of-type': {
            marginRight: '5px',
        },
    },
    [`& .${toggleButtonClasses.selected}`]: {
        borderColor: scssStyles.styleEnvironment === 'encompass' ? '#0d7ac5 !important' : `${scssStyles.customColor1} !important`,
        backgroundColor: scssStyles.styleEnvironment === 'encompass' ? '#0d7ac5 !important' : `${scssStyles.customColor1} !important`,
        color: scssStyles.styleEnvironment === 'encompass' ? 'inherit' : '#fff !important',
        '&:hover': {
            borderColor: scssStyles.styleEnvironment === 'encompass' ? '#0d7ac5' : scssStyles.customColor1,
            backgroundColor: scssStyles.styleEnvironment === 'encompass' ? '#0d7ac5' : scssStyles.customColor1,
        },
    },
}));
export interface IMultiselectFilterOptions {
    eventTypes?: number[];
    driverNames?: string[];
    startDate?: Date;
    endDate?: Date;
    vehicleIds?: string[];
    showFlaggedOnly?: boolean;
    showFlaggedByUserOnly?: boolean;
    status?: VideoEventWorkflowStatusIdEnum[];
}

type MultiSelectFilterBarProps = IMultiSelectFilterBarProps &
    IIntegrationPartnerDataInjectedProps &
    IVideoEventServiceInjectedProps &
    IConfigServiceInjectedProps;

export class _MultiSelectFilterBar extends React.Component<MultiSelectFilterBarProps> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount(): void {
        // Set the default to all events checked, if no events are checked
        this.props.videoEvents.getVideoEventTypes().then(() => {
            // This will reset to all events if no events are selected (necessary to default to all selected on page load)
            // However, if any combination of events is already selected (held for the session in the filter freezer) these will stay selected
            // if you leave the Video Events tab and come back
            if (!this.props.eventFilter.eventTypes || this.props.eventFilter.eventTypes.length == 0) {
                // Need to get the active event types from the Video Event Freezer
                const videoEventfreezer = this.props.videoEvents.getState();
                const { videoEventTypeResults } = videoEventfreezer;
                const videoEventTypes = videoEventTypeResults.data ? videoEventTypeResults.data : [];
                const videoEventTypeEnums = videoEventTypes.map((x) => x.id);
                if (videoEventTypeEnums.length > 0) {
                    this.handleEventDropdownChange(videoEventTypeEnums);
                }
            }
        });
    }

    getIcon(iconFileName: any): string {
        const currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        const currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;

        if (iconFileName == null || iconFileName == '') {
            // Set default image if none is provided for that event type
            return GetImageUrl(currentImagesBucket ? currentImagesBucket : '', currentRegion ? currentRegion : '', 'ic_car.png');
        }

        //Return icon image found in VideoEventType table.
        return GetImageUrl(currentImagesBucket ? currentImagesBucket : '', currentRegion ? currentRegion : '', iconFileName);
    }

    mapEventTypeList = (videoEventTypes: VideoEventTypeResponse[]): IDropdownItem[] => {
        const eventTypeList: IDropdownItem[] = videoEventTypes.map((et) => ({
            label: et.displayName ? et.displayName : '',
            value: et.id ? et.id : 0,
            iconUrl: this.getIcon(et.iconFileName ? et.iconFileName : ''),
        }));
        return SortDropdownItems(eventTypeList);
    };

    /*
        Be sure to use the cloneDeep function when
        copying objects to avoid mutating the 
        original object.
    */

    handleEventDropdownChange = (selectedValues: any[]): void => {
        const eventFilter = _.cloneDeep(this.props.eventFilter);
        eventFilter.eventTypes = selectedValues;
        this.filterEventsMultiselect(eventFilter);
    };

    handleDriverDropdownChange = (selectedValues: any[]): void => {
        const eventFilter = _.cloneDeep(this.props.eventFilter);
        eventFilter.driverNames = selectedValues;
        this.filterEvents(eventFilter);
    };

    handleVehicleDropdownChange = (selectedValues: any[]): void => {
        const eventFilter = _.cloneDeep(this.props.eventFilter);
        eventFilter.vehicleIds = selectedValues;
        this.filterEvents(eventFilter);
    };

    handleDateRangeChange = (dateRange: any): void => {
        const eventFilter = _.cloneDeep(this.props.eventFilter);
        eventFilter.startDate = dateRange.startDate;
        eventFilter.endDate = dateRange.endDate;
        this.filterEvents(eventFilter);
    };

    handleFlagVideoFilter = (): void => {
        const eventFilter = _.cloneDeep(this.props.eventFilter);
        eventFilter.showFlaggedOnly = false;
        eventFilter.showFlaggedByUserOnly = !eventFilter.showFlaggedByUserOnly;
        this.filterEvents(eventFilter);
    };

    handleFlagVideoFilterToggle = (selectedFilterOption: string): void => {
        const eventFilter = _.cloneDeep(this.props.eventFilter);
        if (selectedFilterOption === 'showFlaggedByUserOnly') {
            eventFilter.showFlaggedByUserOnly = !eventFilter.showFlaggedByUserOnly;
            eventFilter.showFlaggedOnly = false;
        } else if (selectedFilterOption === 'showFlaggedOnly') {
            eventFilter.showFlaggedOnly = !eventFilter.showFlaggedOnly;
            eventFilter.showFlaggedByUserOnly = false;
        }
        this.filterEvents(eventFilter);
    };

    handleStatusDropdownChange = (selectedValues: any[]): void => {
        const eventFilter = _.cloneDeep(this.props.eventFilter);
        eventFilter.status = selectedValues;
        this.filterEvents(eventFilter);
    };

    filterEvents = (eventFilter: IMultiselectFilterOptions): void => {
        const { onFilterOptionsChange } = this.props;
        onFilterOptionsChange(eventFilter);
    };

    filterEventsMultiselect = (eventFilter: IMultiselectFilterOptions) => {
        const { onFilterOptionsChange } = this.props;
        onFilterOptionsChange(eventFilter);
    };

    selectedFlagFilterOption = () => {
        if (this.props.eventFilter.showFlaggedByUserOnly) {
            return 'showFlaggedByUserOnly';
        } else if (this.props.eventFilter.showFlaggedOnly) {
            return 'showFlaggedOnly';
        }

        return undefined;
    };

    render(): JSX.Element {
        const { videoEventTypeResults } = this.props.videoEvents.getState();
        const videoEventTypes = videoEventTypeResults.data ? videoEventTypeResults.data : [];
        const eventTypeDropdownList = this.mapEventTypeList(videoEventTypes);
        const eventStatusDropdownList = getVideoEventStatusDropdownList();

        const { hideEventType, hideDrivers, eventFilter, hideFlaggedVideosControl, isMobileView, isVideoRecallPage } = this.props;

        //Default to 0 if the eventType is null/undefined - 0 is the 'All Events' dropdown value
        const selectedEventTypeArray = eventFilter.eventTypes ? eventFilter.eventTypes : [];
        const selectedDriverIds = eventFilter.driverNames ? eventFilter.driverNames : [];
        const selectedVehicleIdArray = eventFilter.vehicleIds ? eventFilter.vehicleIds : [];
        const selectedStatusArray = eventFilter.status ? eventFilter.status : [];

        return (
            <div className={isMobileView ? styles.mainMobile : styles.main}>
                <div className={styles.filter + (hideEventType === true ? ' ' + styles.hidden : '')}>
                    <VPDropdown
                        id="select-events"
                        name="Events"
                        isMultiselect={true}
                        items={eventTypeDropdownList}
                        selectedValues={selectedEventTypeArray}
                        onSelectedItemChange={this.handleEventDropdownChange}
                        width={280}
                    />
                </div>
                <div className={styles.filter + (hideDrivers === true ? ' ' + styles.hidden : '')}>
                    <DriverDropdownMultiselect
                        selectedDriverIds={selectedDriverIds}
                        onSelectedDriverChange={this.handleDriverDropdownChange}
                        drivers={[]}
                        includeInactives={this.props.includeInactiveDrivers}
                        onChangeIncludeInactives={this.props.onChangeIncludeInactiveDrivers}
                    />
                </div>
                <div className={styles.filter}>
                    <VehicleDropdownMultiselect
                        selectedVehicleIds={selectedVehicleIdArray}
                        onSelectedVehicleChange={this.handleVehicleDropdownChange}
                        includeInactives={this.props.includeInactiveVehicles}
                        onChangeIncludeInactives={this.props.onChangeIncludeInactiveVehicles}
                        vehicles={[]}
                    />
                </div>
                <div className={styles.filter + (isVideoRecallPage === true ? ' ' + styles.hidden : '')}>
                    <VPDropdown
                        id="select-event-status"
                        name="Event Status"
                        isMultiselect={true}
                        items={eventStatusDropdownList}
                        selectedValues={selectedStatusArray}
                        onSelectedItemChange={this.handleStatusDropdownChange}
                    />
                </div>
                <div className={styles.filter}>
                    <VPDateRange
                        onSelectedItemChange={this.handleDateRangeChange}
                        startDate={eventFilter.startDate}
                        endDate={eventFilter.endDate}
                        width={scssStyles.styleEnvironment === 'encompass' && isVideoRecallPage ? '200px' : ''}
                        IsMobileView={isMobileView}
                        showMessage={false}
                    />
                </div>
                <div className={styles.filter + (hideFlaggedVideosControl === true ? ' ' + styles.hidden : '')}>
                    <div>
                        <FormControl>
                            <FormLabel className={styles.flaggedToggleLabel}>Flagged Videos</FormLabel>
                            <div className={styles.flaggedToggleContainer}>
                                <StyledToggleButtonGroup>
                                    <ToggleButton
                                        value=""
                                        selected={this.selectedFlagFilterOption() === 'showFlaggedByUserOnly'}
                                        onChange={() => this.handleFlagVideoFilterToggle('showFlaggedByUserOnly')}
                                    >
                                        My Flags
                                    </ToggleButton>
                                    <ToggleButton
                                        value=""
                                        selected={this.selectedFlagFilterOption() === 'showFlaggedOnly'}
                                        onChange={() => this.handleFlagVideoFilterToggle('showFlaggedOnly')}
                                    >
                                        All Flagged
                                    </ToggleButton>
                                </StyledToggleButtonGroup>
                            </div>
                        </FormControl>
                    </div>
                </div>
            </div>
        );
    }
}

export const MultiSelectFilterBar = VideoEventService.inject(
    ConfigService.inject(IntegrationPartnerDataService.inject(_MultiSelectFilterBar)),
);
