// #region Imports

import { bind, managedAjaxUtil, React, Router, s } from 'Imports';
import { Drawer, Toolbar, StyledEngineProvider, CircularProgress, ThemeProvider, styled } from 'MaterialUIComponents';
import { cx } from '@videoplatform/css-helpers';
import { getLogger } from '@videoplatform/logging';
import { ISinglePageApplicationProps } from '@videoplatform/react-spa';

import { MainMenu } from '$Components/MainMenu';

import { ApiContext, IApplicationOptions } from '$Utilities/api';

import { SignedAgreementHistoryResponse } from '$Generated/api';

import { IAgreementsServiceInjectedProps, AgreementsService } from '$State/AgreementsFreezerService';
import { IUserManagementServiceInjectedProps, UserManagementService } from '$State/UserManagementFreezerService';
import { IPricingPlanServiceInjectedProps, PricingPlanService } from '$State/PricingPlanFreezerService';
import { Pendo } from './PendoInitializer';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { GetImageUrl } from '$Utilities/dataModelUtilities';

import { IntegrationPartnerManagerFactory } from 'integrationPartner/integrationPartnerAbstraction';
import { EncompassManager } from 'integrationPartner/encompassManager';
import { GeotabManager } from 'integrationPartner/geotabManager';

import { SplitClient } from '@splitsoftware/splitio-react';
import { getSplitConfig, TypedSplitFactory } from '$Utilities/split-io-sdk';
import { init as initApm } from '@elastic/apm-rum';
import { PageRouter } from '$Pages/PageRouter';
import theme from '$CSS/materialTheme';
import { paperClasses } from '@mui/material/Paper';
// #endregion Imports

// Include the global styles
require('$CSS/global.scss');

const styles = require('Application.scss') as {
    active: string;
    link: string;
    logo: string;
    main: string;
    navbar: string;
    page: string;
    pageOuter: string;
    toolbar: string;
    content: string;
    vpLogoToolbar: string;
    contentToolbarPlaceholder: string;
    mainIntegrated: string;
};

const logger = getLogger('Application');
type RedirectAction = 'Wait' | 'Redirect' | 'Continue';
let apm: any;

interface IApplicationState {
    apiOptions: IApplicationOptions;
    selectedEvent: string;
    allAgreementsSigned: boolean;
    isConfigurationLoaded: boolean;
}

type IApplicationProps = ISinglePageApplicationProps &
    IUserManagementServiceInjectedProps &
    IConfigServiceInjectedProps &
    IIntegrationPartnerDataInjectedProps &
    IAgreementsServiceInjectedProps &
    IPricingPlanServiceInjectedProps;
const StyledDiv = styled('div')({
    ...theme.mixins.toolbar,
});

export class _Application extends React.Component<IApplicationProps, IApplicationState> {
    state = {
        apiOptions: {
            // The current api generator can't handle "/" as a base url, so we replace that with a
            // fully qualified base URL with trailing slashes removed
            applicationRoot: this.props.baseUrl,
            apiBaseUrl: s.rtrim(`${this.props.apiBaseUrl}`, '/'),
            wrappedFetch: fetch,
        },
        selectedEvent: '',
        allAgreementsSigned: false,
        isConfigurationLoaded: false,
    };

    async componentDidMount(): Promise<void> {
        // We don't want to run the full application initialization when handling the token renewal for encompass. Since that Iframe is running a new instance of our app
        if (location.pathname.includes('/silentRenew')) {
            await EncompassManager.completeCredentialRenewal();
            return;
        }

        // Determine what our backend communication urls need to be by looking for certain integrated vs. standalone
        this.props.config.setIsRunningStandAlone(!GeotabManager.isIntegrated() && !EncompassManager.isIntegrated());
        this.saveBaseUrl(
            GeotabManager.isIntegrated() ? GeotabManager.getGeotabBaseUrl(this.props.baseUrl, this.props.apiBaseUrl) : location.origin,
        );

        if (this.props.config.isRunningStandAlone()) {
            // Get configuration from our backend FIRST so we know what IIntegrationPartnerManager to use
            await this.setupConfiguration();
            this.props.integrationPartnerData.setIntegrationPartnerManager(IntegrationPartnerManagerFactory.getIntegrationPartnerService());
            this.props.integrationPartnerData.getIntegrationPartnerManager().configureApplicationForStandAloneMode();
        } else {
            // Need to rely on "isIntegrated()" checks to determine what IIntegrationPartnerManager to use so that we can setup any integration parter hooks RIGHT
            // AWAY, otherwise we miss some messages on startup waiting to get our configuration from the backend :-(.
            this.props.integrationPartnerData.setIntegrationPartnerManager(IntegrationPartnerManagerFactory.getIntegrationPartnerService());
            await this.props.integrationPartnerData
                .getIntegrationPartnerManager()
                .configureApplicationForIntegratedMode(async () => this.onSelectVideoEvent(''));
            await this.setupConfiguration();
        }

        apm = initApm({
            // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
            serviceName: ConfigService.getApmServiceName(), //'ui_vprotect',
            // Set custom APM Server URL
            serverUrl: ConfigService.getApmServiceUrl(),
            // Set service environment (required for sourcemap feature)
            environment: ConfigService.getDeployEnvironment(),
        });
        apm.addLabels({ IntegrationPlatform: ConfigService.getIntegrationPlatform() });
        // This function creates visitors and accounts in Pendo
        // You will need to replace <visitor-id-goes-here> and <account-id-goes-here> with values you use in your app
        // Please use Strings, Numbers, or Bools for value types.

        setTimeout(
            async () => {
                await this.props.userManagement.tryLogin();
                if (
                    this.props.config.isRunningStandAlone() &&
                    !this.props.userManagement.isLoggedIn() &&
                    !location.pathname.includes('/login')
                ) {
                    location.href = '/login';
                } else if (!this.props.config.isRunningEncompassIntegrated()) {
                    // NOTE: This is called later on for Integrated Encompass, after credentials are sent to us via a message from Encompass
                    await this.props.userManagement.setupUserData();
                }
                apm.addLabels({ UserName: IntegrationPartnerDataService.getUsername() });

                if (
                    ConfigService.getIntegrationPlatform() === 'geotab' &&
                    (ConfigService.isDeployEnvironmentDev() ||
                        ConfigService.isDeployEnvironmentQas() ||
                        ConfigService.isDeployEnvironmentProd())
                ) {
                    //ConfigService.isDeployEnvironmentStage() || ConfigService.isDeployEnvironmentProd() add this after testing
                    Pendo.initializePendo();
                    Pendo.UpdatePendo(
                        UserManagementService.getUserCredential(),
                        ConfigService.getDeployEnvironment(),
                        ConfigService.getIntegrationPlatform(),
                        ConfigService.getFleetIdString(),
                    );
                }
            },
            ConfigService.isIntegrationPlatformGeotab() ? 1500 : 0,
        );
    }

    async setupConfiguration(): Promise<void> {
        await this.props.config.getClientConfig();

        const showAgreements = this.props.config.getState().clientConfigResults.data?.showAgreements;
        this.props.agreements.setShowAgreements(showAgreements);

        this.props.setFaviconUrl(this.GetImageBucketUrl('favicon.ico'));

        this.setState({ isConfigurationLoaded: true });
    }

    @bind
    isUnrestrictedRoute(route: string): boolean {
        return route.includes('/login') || route.includes('/error');
    }

    @bind
    onSelectVideoEvent(eventId: string, goBack: boolean = false, addHistoryState = true): void {
        if (eventId) {
            const eventUrl = this.props.config.isRunningGeotabIntegrated() ? ',eventId:' : '?eventId=';
            const pathName = `${location.pathname}${location.hash}${location.pathname.includes(eventUrl) ? '' : eventUrl}`;

            if (addHistoryState) {
                history.pushState(null, 'Video Event Details', `${pathName}'${eventId}'`);
            } else {
                history.replaceState(null, 'Video Event Details', `${pathName}'${eventId}'`);
            }
        } else if (goBack) {
            history.back();
        }

        this.setState({ selectedEvent: eventId?.toString() });
    }

    @bind
    saveBaseUrl(url: string) {
        this.props.config.setBaseUrl(url);
        managedAjaxUtil.setBaseUrl(s.rtrim(`${url}`, '/'));
    }

    @bind
    GetImageBucketUrl(filename: string): string {
        const currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        const currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;
        // If config hasn't been set yet, return an empty location for the image src
        return currentImagesBucket && currentRegion ? GetImageUrl(currentImagesBucket, currentRegion, filename) : '//:0';
    }

    @bind
    needToSign(signedAgreementsResults: managedAjaxUtil.IAjaxState<SignedAgreementHistoryResponse>): RedirectAction {
        // return "Continue" if showAgreements is false and we want to pass through without them
        if (!this.props.agreements.getShowAgreements()) {
            return 'Continue';
        }

        // if we don't have the agreements yet, we don't know if we need to sign or not
        const signedAgreement = signedAgreementsResults.data;
        const hasError = signedAgreementsResults.error;
        const isFetching = signedAgreementsResults.isFetching;

        // if we haven't pulled the agreement yet, wait
        if ((signedAgreement === null || signedAgreement === undefined) && isFetching) {
            return 'Wait';
        }

        // if we have an error state, redirect to the agreements page - see if we can resolve it.
        if (hasError) {
            return 'Redirect';
        }

        let needToSign: RedirectAction = 'Continue';
        if (signedAgreement !== null && signedAgreement !== undefined) {
            if (!signedAgreement.matches) {
                needToSign = 'Redirect';
            }
        }
        return needToSign;
    }

    render() {
        logger.info('Rendering application');
        const { signedAgreementsResults } = this.props.agreements.getState();
        const redirectToAgreements = this.needToSign(signedAgreementsResults);
        const splitConfig = getSplitConfig();
        //show loading indicator during application initialization
        if (
            !this.state.isConfigurationLoaded || //show indicator until application confguration is finished loading
            !this.props.integrationPartnerData.getCanRender() || //show indicator until integrationPartner indicates it's ready
            (!this.props.userManagement.getIsUserDataLoaded() && !this.isUnrestrictedRoute(location.pathname)) || //show indicator until user data is loaded unless the route is unrestricted
            redirectToAgreements === 'Wait'
        ) {
            //show indicator if agreements are still loading
            return <CircularProgress />;
        }

        return (
            <TypedSplitFactory
                config={splitConfig}
                updateOnSdkReady={true}
                updateOnSdkReadyFromCache={true}
                updateOnSdkTimedout={true}
                updateOnSdkUpdate={false}
            >
                <SplitClient
                    splitKey={this.props.config.getSplitClientKey()}
                    updateOnSdkReady={true}
                    updateOnSdkReadyFromCache={true}
                    updateOnSdkTimedout={true}
                    updateOnSdkUpdate={false}
                >
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <div
                                className={cx([styles.main])}
                                style={{
                                    flexGrow: 1,
                                    height: 430,
                                    zIndex: 1,
                                    overflow: 'hidden',
                                    position: 'relative',
                                    display: 'flex',
                                }}
                            >
                                <Router>
                                    <ApiContext.Provider value={this.state.apiOptions}>
                                        {this.props.config.isRunningStandAlone() ? (
                                            <Drawer
                                                variant="permanent"
                                                classes={{
                                                    paper: `${styles.navbar}`,
                                                }}
                                                sx={{ [`& .${paperClasses.root}`]: { position: 'relative', width: 305 } }}
                                            >
                                                <Toolbar className={styles.vpLogoToolbar}>
                                                    <img className={styles.logo} src={this.GetImageBucketUrl('logo.png')} />
                                                </Toolbar>
                                                {/* App Bar Placeholder */}
                                                <StyledDiv className={styles.contentToolbarPlaceholder} />

                                                <MainMenu />
                                            </Drawer>
                                        ) : (
                                            ''
                                        )}
                                        <main className={styles.content} style={{ backgroundColor: theme.palette.background.default }}>
                                            {/* App Bar Placeholder */}
                                            <StyledDiv className={styles.contentToolbarPlaceholder} />

                                            <div className={styles.pageOuter}>
                                                <div className={styles.page}>
                                                    <PageRouter
                                                        {...this.props}
                                                        needToSign={this.needToSign}
                                                        onSelectVideoEvent={this.onSelectVideoEvent}
                                                        selectedEvent={this.state.selectedEvent}
                                                    />
                                                </div>
                                            </div>
                                        </main>
                                    </ApiContext.Provider>
                                </Router>
                            </div>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </SplitClient>
            </TypedSplitFactory>
        );
    }
}

const Application = UserManagementService.inject(
    AgreementsService.inject(ConfigService.inject(IntegrationPartnerDataService.inject(PricingPlanService.inject(_Application)))),
);

export { Application, IApplicationProps, apm };
