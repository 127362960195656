import { React, bind, moment, _ } from 'Imports';
import { Button, CircularProgress, Backdrop } from 'MaterialUIComponents';
import { BulkRequest, BulkRatingRequest, VideoEventFullListResponse, VideoEventResponse } from '$Generated/api';
import { DeleteConfirmation } from '$Components/Shared/DeleteConfirmation';
import { DismissConfirmation } from '$Components/Shared/DismissConfirmation';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FilterBreadcrumbsMultiselect } from '$Components/FilterComponents/FilterBreadcrumbsMultiselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forEach } from 'lodash';
import { getSplitUserAttributes, isSplitTreatmentOn, TypedSplitTreatments, splitTreatmentNames } from '$Utilities/split-io-sdk';
import { GridListToggleButton } from '$Components/Shared/GridListToggleButton';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
import { IPricingPlanServiceInjectedProps, PricingPlanService } from '$State/PricingPlanFreezerService';
import { IVideoEventServiceInjectedProps, VideoEventService } from '$State/VideoEventFreezerService';
import {
    IVideoEventFilterServiceMultiselectInjectedProps,
    VideoEventFilterServiceMultiselect,
} from '$State/VideoEventFilterFreezerServiceMultiselect';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import { IntegrationPartnerDataService, IIntegrationPartnerDataInjectedProps } from '$State/IntegrationPartnerDataFreezerService';
import { MultiSelectFilterBar, IMultiselectFilterOptions } from '$Components/FilterComponents/MultiSelectFilterBar';
import { RolesEnum, canEditByRole } from '$Externals/VerifyRole';
import { VideoEventCardList } from '$Components/VideoEvents/VideoEventCardList/VideoEventCardList';
import { VideoEventListMultiSelect } from '$Components/VideoEvents/VideoEventList/VideoEventListMultiSelect';
import { VideoEventExport } from '$Components/VideoEvents/VideoEventExport/VideoEventExport';
import { VideoEventsHeader } from '$Components/VideoEvents/VideoEventHeader/VideoEventsHeader';

import * as scssStyles from '$CSS/settings.scss';

const styles = require('$Pages/VideoEvents/VideoEvents.scss') as {
    main: string;
    filterContainer: string;
    filterContainerMobile: string;
    filterBar: string;
    filterBackdrop: string;
    viewTypeToggleContainer: string;
    viewTypeToggle: string;
    showUnprocessedEvents: string;
    searchButton: string;
    exportButton: string;
    buttonContainer: string;
    trayTitle: string;
    trayClose: string;
    inactiveToggleIcon: string;
    activeToggleIcon: string;
    noVideos: string;
    activeSearchButton: string;
    searchIcon: string;
};

interface IVideoEventsState {
    events: VideoEventResponse[];
    eventIds: VideoEventFullListResponse;
    csvTitle: string;
    backdropVisible: boolean;
    headerOpened: boolean;
    filtersVisible: boolean;
    isMobileView: boolean;
    shrinkHeight: number;
    hasVideoEventScoring: boolean;
    hasVideoEventStatusWorkflow: boolean;
    showDeletePopup: boolean;
    deleteButtonDisabled: boolean;
    selectedEventIds: number[];
    isBulk: boolean;
    showDismissPopup: boolean;
}

interface IVideoEventsBaseProps {
    onSelectVideoEvent: (eventId: string, goBack: boolean) => void;
    groupsFilter?: string;
}

type IVideoEventsProps = IVideoEventsBaseProps &
    IVideoEventServiceInjectedProps &
    IConfigServiceInjectedProps &
    IVideoEventFilterServiceMultiselectInjectedProps &
    IPricingPlanServiceInjectedProps &
    IIntegrationPartnerDataInjectedProps;

let isMounted = false;

class _VideoEventsPage extends React.Component<IVideoEventsProps, IVideoEventsState> {
    videoEventsHeaderDiv: any;
    filterHeaderDiv: any;
    filterBarDiv: any;

    state: IVideoEventsState = {
        events: [],
        eventIds: {},
        csvTitle: 'VideoEvents',
        backdropVisible: false,
        filtersVisible: false,
        headerOpened: false,
        isMobileView: false,
        shrinkHeight: 0,
        hasVideoEventScoring: false,
        hasVideoEventStatusWorkflow: false,
        showDeletePopup: false,
        deleteButtonDisabled: false,
        selectedEventIds: [],
        isBulk: false,
        showDismissPopup: false,
    };

    async getVideoEvents(): Promise<void> {
        VideoEventService.clearVideoEvents();
        this.props.videoEventFilterMultiselect.setPageNumber(1);
        const eventFilter = _.cloneDeep(this.props.videoEventFilterMultiselect.getVideoEventFilter());

        //set aside the prev saved filters for driver and vehicle
        const prevDriverFilter = eventFilter.driverNames;
        const prevVehicleFilter = eventFilter.vehicleIds;

        //clear up filter vehicle and driver ids the very first time loading video events page.
        eventFilter.driverNames = [];
        eventFilter.vehicleIds = [];

        //get results from integrationPartnerData (this will iclude Geotab groups if necessary), this is what should be
        // loaded in the drop downs.
        const integrationDrivers = this.props.integrationPartnerData.getState().driversResults;
        const integrationVehicles = this.props.integrationPartnerData.getState().deviceResults;

        //compare the prev filters for drivers and vehicles to what is in the integration data for drivers and vehicles.
        //add to the eventFilter only those that are matching
        if (prevDriverFilter?.some((s) => s == '-1')) {
            eventFilter.driverNames?.push('-1');
        }
        forEach(integrationDrivers.data, function (d) {
            if (prevDriverFilter && prevDriverFilter.indexOf(d.id) > -1) {
                eventFilter.driverNames?.push(d.id);
            }
        });
        forEach(integrationVehicles.data, function (v) {
            if (prevVehicleFilter && prevVehicleFilter.indexOf(v.id) > -1) {
                eventFilter.vehicleIds?.push(v.id);
            }
        });

        //apply the filters and get the data
        if (!eventFilter.eventTypes || eventFilter.eventTypes.length === 0) {
            await this.props.videoEvents.getVideoEventTypes();
            const videoEventfreezer = this.props.videoEvents.getState();
            const { videoEventTypeResults } = videoEventfreezer;
            const videoEventTypes = videoEventTypeResults.data ? videoEventTypeResults.data : [];
            const videoEventTypeEnums = videoEventTypes.map((x) => x.id);
            const eventFilterCopy = _.cloneDeep(eventFilter);
            eventFilterCopy.eventTypes = videoEventTypeEnums as any[];
            await this.handleFilterEventsMultiselect(eventFilterCopy);
        } else {
            await this.handleFilterEventsMultiselect(eventFilter);
        }
    }

    async getVideoEventRatings(): Promise<void> {
        await VideoEventService.getVideoEventRatings();
    }
    async componentDidMount(): Promise<void> {
        window.addEventListener('resize', this.onWindowResize);

        isMounted = true;

        await this.props.integrationPartnerData.getDevices();
        await this.getVideoEventRatings();
        await this.getVideoEvents();
        this.onWindowResize();
        /*
          Due to the fact that this component may mount more than 
          once, we need to check if it is still
          mounted at the time of receiving a result instead
          of using await.
        */
        this.props.pricingPlan.hasFeatureType('VideoEventScoring').then((result) => {
            if (isMounted) {
                this.setState({ hasVideoEventScoring: result });
            }
        });

        this.props.pricingPlan.hasFeatureType('VideoEventStatusWorkflow').then((result) => {
            if (isMounted) {
                this.setState({ hasVideoEventStatusWorkflow: result });
            }
        });
    }

    async componentWillUnmount(): Promise<void> {
        isMounted = false;
        window.removeEventListener('resize', this.onWindowResize);
    }

    async componentDidUpdate(prevProps: Readonly<IVideoEventsProps>): Promise<void> {
        if (this.props.groupsFilter !== prevProps.groupsFilter) {
            await IntegrationPartnerDataService.getDrivers();
            await IntegrationPartnerDataService.getDevices();

            //we know the group drivers at this point
            await this.getVideoEvents();
        }
    }

    @bind
    onWindowResize(): void {
        //Navbar takes up 305px so we can display the filter bar on smaller screen sizes if the navbar is hidden
        if (isMounted) {
            if (this.props.config.isRunningStandAlone() ? window.innerWidth < 1858 : window.innerWidth < 1553) {
                this.setState({ isMobileView: true, filtersVisible: false });
            } else {
                this.setState({ isMobileView: false, filtersVisible: false });
            }
            this.setShrinkHeight();
        }
    }

    @bind
    handleEventLayoutChange(e: any, value: 'tile' | 'list'): void {
        if (value !== null) {
            VideoEventService.clearVideoEvents();
            this.props.videoEventFilterMultiselect.setPageNumber(1);
            this.props.videoEventFilterMultiselect.setSortFields('date', false);
            const eventFilter = this.props.videoEventFilterMultiselect.getVideoEventFilter();
            this.props.videoEvents.getFilteredEventsMultiselect(eventFilter);
            this.props.videoEvents.setVideoEventViewType(value);
        }
    }

    @bind
    toggleFilters(): void {
        const { filtersVisible } = this.state;
        this.setState({
            filtersVisible: !filtersVisible,
        });
    }

    @bind
    async handleFilterEventsMultiselect(filterOptions: IMultiselectFilterOptions): Promise<void> {
        if (isMounted) {
            this.props.videoEvents.clearVideoEvents();
            this.props.videoEventFilterMultiselect.setPageNumber(1);
            this.props.videoEventFilterMultiselect.updateFilterOptions(
                filterOptions.eventTypes,
                filterOptions.driverNames,
                filterOptions.vehicleIds,
                filterOptions.startDate,
                filterOptions.endDate,
                filterOptions.showFlaggedOnly,
                filterOptions.showFlaggedByUserOnly,
                filterOptions.status,
                this.props.integrationPartnerData.getGroupsFilter(),
            );

            if (filterOptions.startDate && filterOptions.endDate) {
                await this.setCsvTitle(
                    moment(filterOptions.startDate).utc().format('YYYY_MM_DD'),
                    moment(filterOptions.endDate).utc().format('YYYY_MM_DD'),
                );
            }

            const videoEventFilter = this.props.videoEventFilterMultiselect.getVideoEventFilter();
            await this.props.videoEvents.getFilteredEventsMultiselect(videoEventFilter);
            await this.props.videoEvents.getVideoEventStatisticsMultiselect(videoEventFilter);
            await this.props.videoEvents.getListOfEventIds(videoEventFilter);

            const freezer = this.props.videoEvents.getState();
            const { videoEventResults } = freezer;

            this.setState({
                events: videoEventResults.data && videoEventResults.data.data ? videoEventResults.data.data : [],
            });
        }
    }

    @bind
    async setCsvTitle(startDate: string, endDate: string): Promise<void> {
        let title = 'Video Events';
        title += '_' + startDate;
        if (startDate !== endDate) {
            title += '-' + endDate;
        }
        this.setState({ csvTitle: title });
    }

    @bind
    async _onPageNumberChanged(newPage: number): Promise<void> {
        this.props.videoEventFilterMultiselect.setPageNumber(newPage);
        const eventFilter = this.props.videoEventFilterMultiselect.getVideoEventFilter();
        this.props.videoEvents.getFilteredEventsMultiselect(eventFilter);
    }

    @bind
    async gridSort(sortBy: string, sortAsc: boolean): Promise<void> {
        VideoEventService.clearVideoEvents();
        this.props.videoEventFilterMultiselect.setPageNumber(1);
        this.props.videoEventFilterMultiselect.setSortFields(sortBy, sortAsc);
        const eventFilter = this.props.videoEventFilterMultiselect.getVideoEventFilter();
        await this.props.videoEvents.getFilteredEventsMultiselect(eventFilter);
    }

    @bind
    async loadVideoEventsOnScroll(): Promise<void> {
        if (!this.props.videoEvents.getState().videoEventResults.isFetching) {
            const eventFilter = this.props.videoEventFilterMultiselect.getVideoEventFilter();
            const currentPage = eventFilter.currentPage;
            if (currentPage && currentPage !== undefined) {
                let newPage = currentPage;
                newPage++;
                setTimeout(async (): Promise<void> => {
                    await this._onPageNumberChanged(newPage);
                }, 1500);
            }
        }
    }

    @bind
    onHeaderChanged(opened: boolean): void {
        this.setState({ headerOpened: opened });
        setTimeout(() => this.setShrinkHeight(), 500); // need to finetune this
    }

    @bind
    toggleEvents(): void {
        this.props.videoEventFilterMultiselect.toggleShowOnlySuccessfulVideoEvents();
        this.props.videoEventFilterMultiselect.setPageNumber(1);
        const eventFilter = this.props.videoEventFilterMultiselect.getVideoEventFilter();
        VideoEventService.clearVideoEvents();
        this.props.videoEvents.getFilteredEventsMultiselect(eventFilter);
        this.props.videoEvents.getVideoEventStatisticsMultiselect(eventFilter);
    }

    @bind
    flagEvent(eventId: number | undefined): void {
        if (!canEditByRole([RolesEnum.videoEventEdit])) return;
        this.props.videoEvents.flagVideoEvent(eventId).then(() => {
            const { videoEventResultsFinal } = this.props.videoEvents.getState();
            /* if the current list of events has an event matching this event id,
               then that means it was toggled off. if it was toggled off and the
               showFlaggedByUserOnly filter option is true, then the list needs 
               to be re-filtered.
            */
            const { id } = this.props.integrationPartnerData.getState().currentUserResult.data;
            const eventFilter = this.props.videoEventFilterMultiselect.getVideoEventFilter();
            const event = _.find(videoEventResultsFinal, (event) => event.id === eventId);
            const flaggedByUsers = event?.flaggedByUsers || [];
            const shouldReloadVideoEvents =
                event &&
                (eventFilter.showFlaggedByUserOnly ||
                    (eventFilter.showFlaggedOnly && flaggedByUsers.length === 1 && flaggedByUsers[0].integrationProviderSourceId === id));
            if (shouldReloadVideoEvents) {
                VideoEventService.clearVideoEvents();
                this.props.videoEvents.getFilteredEventsMultiselect(eventFilter);
            }
        });
    }

    @bind
    onRateVideo(eventId: number | undefined, rating: number, status: string): void {
        if (this.state.hasVideoEventScoring) {
            this.props.videoEvents.rateVideoEvent(eventId, rating);
            if (status == 'New') {
                this.props.videoEvents.updateLocalVideoEventStatus('In_Progress', eventId);
            }
        }
    }

    @bind
    onClearRating(eventId: number | undefined): void {
        if (this.state.hasVideoEventScoring) {
            this.props.videoEvents.clearRating(eventId);
        }
    }

    @bind
    onReopenEvent(eventId: number | undefined): void {
        if (this.state.hasVideoEventStatusWorkflow) {
            this.props.videoEvents.reopenEvent(eventId);
        }
    }

    @bind
    onDismissVideo(eventId: number | undefined): void {
        if (this.state.hasVideoEventStatusWorkflow) {
            this.props.videoEvents.dismissVideoEvent(eventId);
        }
    }

    @bind
    async handleBulkDismissVideo(body: BulkRequest | undefined): Promise<boolean> {
        if (this.state.hasVideoEventStatusWorkflow) {
            return this.props.videoEvents.dismissBulkVideoEvent(body);
        }
        return false;
    }
    @bind
    async handleSubmitBulkScore(body: BulkRatingRequest): Promise<boolean> {
        if (this.state.hasVideoEventStatusWorkflow) {
            return await this.props.videoEvents.postVideoEventRatings(body);
        }
        return false;
    }

    @bind
    async getFullListOfEventIds(): Promise<number[]> {
        if (this.state.hasVideoEventStatusWorkflow) {
            const videoEventFilter = this.props.videoEventFilterMultiselect.getVideoEventFilter();
            return await this.props.videoEvents.getListOfEventIds(videoEventFilter);
        }
        return [];
    }

    @bind
    async handleVideoPlayed(eventId: number | undefined) {
        this.props.videoEvents.updateVideoEventWorkflowStatus(eventId, 'In_Progress');
        if (eventId) {
            const type = this.props.videoEvents.GetUploadTypeString('FrontVideo');
            await this.props.videoEvents.addVideoEventActionRecord(eventId, 'PlayedVideo', `${type} watched from video event page`);
        }
    }

    @bind
    onChangeIncludeInactiveDrivers(includeInactives?: boolean) {
        this.props.videoEvents.setIncludeInactiveDrivers(includeInactives);
    }

    @bind
    onChangeIncludeInactiveVehicles(includeInactives?: boolean) {
        this.props.videoEvents.setIncludeInactiveVehicles(includeInactives);
    }

    @bind
    calculateShrinkHeight(): number {
        let height = 0;
        if (this.videoEventsHeaderDiv) {
            height += this.videoEventsHeaderDiv.offsetHeight;
        }
        if (this.filterHeaderDiv) {
            height += this.filterHeaderDiv.offsetHeight;
        }
        if (this.filterBarDiv) {
            height += this.filterBarDiv.offsetHeight;
        }
        return height;
    }

    setShrinkHeight(): void {
        if (isMounted) {
            const height = this.calculateShrinkHeight();
            this.setState({ shrinkHeight: height });
        }
    }

    @bind
    refreshVideoEventsList(): void {
        this.props.videoEvents.refreshVideoEvents();
        this.props.videoEvents.refreshVideoEventStatistics();
    }

    @bind
    refreshVideoEventsListForDisable(): void {
        this.props.videoEvents.refreshVideoEvents();
        const videoEventFilter = this.props.videoEventFilterMultiselect.getVideoEventFilter();
        this.props.videoEvents.getListOfEventIds(videoEventFilter);
        this.props.videoEvents.refreshVideoEventStatistics();
    }

    @bind
    toggleDeletePopup(selectedId?: number): void {
        if (selectedId) {
            this.setState({ selectedEventIds: selectedId ? [selectedId] : [] });
        }
        this.setState({ showDeletePopup: !this.state.showDeletePopup, isBulk: false });
    }

    @bind
    toggleDismissPopup(selectedId?: number): void {
        if (selectedId) {
            this.setState({ selectedEventIds: selectedId ? [selectedId] : [] });
        }
        this.setState({ showDismissPopup: !this.state.showDismissPopup });
    }

    @bind
    changeToDisabled(disabled: any): void {
        this.setState({ deleteButtonDisabled: disabled });
    }

    @bind
    bulkDeleteDialogOpen(open: boolean, selectedIds: number[]): void {
        this.setState({ selectedEventIds: selectedIds, showDeletePopup: open, isBulk: true });
    }

    render(): JSX.Element {
        const freezer = this.props.videoEvents.getState();
        const { videoEventResults, videoEventResultsFinal, videoEventStatisticsResults, videoEventViewType, videoEventRatings } = freezer;
        const totalVideoRecords = videoEventResults.data && videoEventResults.data.data ? (videoEventResults.data.totalCount as number) : 0;
        const data = videoEventResultsFinal.length > 0 ? videoEventResultsFinal : [];
        const history = videoEventStatisticsResults.data == null ? [] : videoEventStatisticsResults.data;
        const hasMorePagesToLoad =
            !freezer.hasInfiniteScrollError && (videoEventResults.data?.currentPage ?? 0) < (videoEventResults.data?.totalPages ?? 0)
                ? true
                : false;
        const { filtersVisible, shrinkHeight, selectedEventIds, showDeletePopup, isBulk, showDismissPopup } = this.state;
        const { videoEventFilterMultiselect } = this.props;

        const videoEventList = (showEventId: boolean) => (
            <VideoEventListMultiSelect
                items={data}
                onGridSort={this.gridSort}
                onSelectVideoEvent={this.props.onSelectVideoEvent}
                loadNextPage={this.loadVideoEventsOnScroll}
                morePagesToLoad={hasMorePagesToLoad}
                headerOpened={this.state.headerOpened}
                onFlagVideoEvent={this.flagEvent}
                onRateVideo={this.onRateVideo}
                onDismissVideo={this.onDismissVideo}
                handleBulkDismissVideo={this.handleBulkDismissVideo}
                onReopenEvent={this.onReopenEvent}
                onClearRating={this.onClearRating}
                filters={this.props.videoEventFilterMultiselect.getVideoEventFilter()}
                onUpdateFilters={this.handleFilterEventsMultiselect}
                isMobileView={this.state.isMobileView}
                videoEventRatings={videoEventRatings.data!}
                handleSubmitBulkScore={this.handleSubmitBulkScore}
                pageNumber={videoEventFilterMultiselect.getVideoEventFilter().currentPage!}
                itemsPerPage={videoEventFilterMultiselect.getVideoEventFilter().itemsPerPage!}
                totalItemsCount={totalVideoRecords}
                getFullListOfEventIds={this.getFullListOfEventIds}
                refreshVideoEventsList={this.refreshVideoEventsList}
                showEventId={showEventId}
                toggleDeletePopup={this.toggleDeletePopup}
                deleteButtonDisabled={this.state.deleteButtonDisabled}
                bulkDeleteDialogOpen={this.bulkDeleteDialogOpen}
                toggleDismissPopup={this.toggleDismissPopup}
            />
        );

        return (
            <div className={styles.main}>
                {
                    <div ref={(ref) => (this.videoEventsHeaderDiv = ref)}>
                        <VideoEventsHeader eventHistory={history} onChange={this.onHeaderChanged} />
                    </div>
                }
                <div
                    ref={(ref) => (this.filterHeaderDiv = ref)}
                    className={this.state.isMobileView ? styles.filterContainerMobile : styles.filterContainer}
                >
                    {this.state.isMobileView ? (
                        <div className={styles.buttonContainer}>
                            <Button
                                aria-label="search"
                                onClick={this.toggleFilters}
                                className={[styles.searchButton, filtersVisible ? styles.activeSearchButton : ''].join(' ')}
                            >
                                Filters
                            </Button>
                            <Backdrop className={styles.filterBackdrop} open={this.state.filtersVisible}>
                                {' '}
                            </Backdrop>
                        </div>
                    ) : (
                        <MultiSelectFilterBar
                            onFilterOptionsChange={this.handleFilterEventsMultiselect}
                            eventFilter={this.props.videoEventFilterMultiselect.getVideoEventFilter()}
                            isMobileView={false}
                            isVideoRecallPage={false}
                            hideFlaggedVideosControl={false}
                            includeInactiveDrivers={this.props.videoEvents.getIncludeInactiveDrivers()}
                            onChangeIncludeInactiveDrivers={this.onChangeIncludeInactiveDrivers}
                            includeInactiveVehicles={this.props.videoEvents.getIncludeInactiveVehicles()}
                            onChangeIncludeInactiveVehicles={this.onChangeIncludeInactiveVehicles}
                        />
                    )}
                    <div style={{ marginRight: '10px' }}>
                        <VideoEventExport csvTitle={this.state.csvTitle} />
                        <GridListToggleButton
                            handleEventLayoutChange={this.handleEventLayoutChange}
                            videoEventViewType={videoEventViewType}
                        />
                    </div>
                </div>
                {this.state.filtersVisible && (
                    <div className={styles.filterBar} ref={(ref) => (this.filterBarDiv = ref)}>
                        <FontAwesomeIcon icon={faTimes} className={styles.trayClose} onClick={this.toggleFilters} />
                        <div className={styles.trayTitle}>Filter Videos</div>
                        <MultiSelectFilterBar
                            onFilterOptionsChange={this.handleFilterEventsMultiselect}
                            eventFilter={this.props.videoEventFilterMultiselect.getVideoEventFilter()}
                            isMobileView={true}
                            isVideoRecallPage={false}
                            hideFlaggedVideosControl={false}
                            includeInactiveDrivers={this.props.videoEvents.getIncludeInactiveDrivers()}
                            onChangeIncludeInactiveDrivers={this.onChangeIncludeInactiveDrivers}
                            includeInactiveVehicles={this.props.videoEvents.getIncludeInactiveVehicles()}
                            onChangeIncludeInactiveVehicles={this.onChangeIncludeInactiveVehicles}
                        />
                    </div>
                )}
                <div>
                    {data.length === 0 && (
                        <div>
                            {this.state.isMobileView && (
                                <FilterBreadcrumbsMultiselect
                                    filters={this.props.videoEventFilterMultiselect.getVideoEventFilter()}
                                    onUpdateFilters={this.handleFilterEventsMultiselect}
                                />
                            )}
                            <div className={styles.noVideos}>
                                {this.props.videoEvents.getVideosLoaded() ? (
                                    <div>No Video Events for the criteria selected</div>
                                ) : (
                                    <CircularProgress></CircularProgress>
                                )}
                            </div>
                        </div>
                    )}
                    {videoEventViewType === 'tile'
                        ? data.length > 0 && (
                              <VideoEventCardList
                                  items={data}
                                  onSelectVideoEvent={this.props.onSelectVideoEvent}
                                  loadNextPage={this.loadVideoEventsOnScroll}
                                  morePagesToLoad={hasMorePagesToLoad}
                                  headerOpened={this.state.headerOpened}
                                  onFlagVideoEvent={this.flagEvent}
                                  onRateVideo={this.onRateVideo}
                                  onDismissVideo={this.onDismissVideo}
                                  onClearRating={this.onClearRating}
                                  handleVideoPlayed={this.handleVideoPlayed}
                                  onReopenEvent={this.onReopenEvent}
                                  shrinkHeight={shrinkHeight}
                                  filters={this.props.videoEventFilterMultiselect.getVideoEventFilter()}
                                  onUpdateFilters={this.handleFilterEventsMultiselect}
                                  isMobileView={this.state.isMobileView}
                                  toggleDeletePopup={this.toggleDeletePopup}
                                  deleteButtonDisabled={this.state.deleteButtonDisabled}
                                  toggleDismissPopup={this.toggleDismissPopup}
                              />
                          )
                        : data.length > 0 && (
                              <TypedSplitTreatments names={[splitTreatmentNames.videoeventId]} attributes={getSplitUserAttributes()}>
                                  {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                      return isReady || isTimedout
                                          ? isSplitTreatmentOn(treatments[splitTreatmentNames.videoeventId])
                                              ? videoEventList(true)
                                              : videoEventList(false)
                                          : videoEventList(false);
                                  }}
                              </TypedSplitTreatments>
                          )}
                </div>
                <TypedSplitTreatments names={[splitTreatmentNames.showUnProcessedEvents]} attributes={getSplitUserAttributes()}>
                    {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                        return isReady || isTimedout ? (
                            isSplitTreatmentOn(treatments[splitTreatmentNames.showUnProcessedEvents]) ? (
                                <Button className={styles.showUnprocessedEvents} onClick={this.toggleEvents}>
                                    {this.props.videoEventFilterMultiselect.getState().showVideoPipelineSuccessOnly
                                        ? 'Show Unprocessed Events'
                                        : 'Hide Unprocessed Events'}
                                </Button>
                            ) : (
                                <div></div>
                            )
                        ) : (
                            <div></div>
                        );
                    }}
                </TypedSplitTreatments>
                <DeleteConfirmation
                    eventIds={selectedEventIds}
                    showDeletePopup={showDeletePopup}
                    toggleDeletePopup={this.toggleDeletePopup}
                    changeToDisabled={this.changeToDisabled}
                    refreshVideoEvent={this.refreshVideoEventsList}
                    isBulk={isBulk}
                />
                <DismissConfirmation
                    eventIds={selectedEventIds}
                    showDeletePopup={showDismissPopup}
                    toggleDeletePopup={this.toggleDismissPopup}
                    changeToDisabled={this.changeToDisabled}
                    onDismissVideo={this.onDismissVideo}
                    refreshVideoEvent={this.refreshVideoEventsListForDisable}
                />
            </div>
        );
    }
}

export const VideoEventsPage = PricingPlanService.inject(
    VideoEventFilterServiceMultiselect.inject(
        VideoEventService.inject(ConfigService.inject(IntegrationPartnerDataService.inject(_VideoEventsPage))),
    ),
);
