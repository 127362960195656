import { VideoEventResponse } from '$Generated/api';
import { moment } from 'Imports';
import { IIntegrationPartnerManager } from 'integrationPartner/integrationPartnerAbstraction';
import { IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { ConfigService } from '$State/ConfigFreezerService';
import { UserManagementService } from '$State/UserManagementFreezerService';

const GeotabApi: any = require('mg-api-js');

export interface GeotabUserCredentials {
    Name: string;
    SessionId: string;
    Database: string;
    Server: string;
}

export class GeotabManager implements IIntegrationPartnerManager {
    geotabApiObject: any;
    private isInitialized: boolean = false;

    public validateGeotabGroupsFilter(): void {
        var regExp = /(?!filter:!\()([a-zA-Z0-9,]*)(?=\))/;
        var geotabGroups = regExp.exec(location.hash);
        IntegrationPartnerDataService.setGroupsFilter(geotabGroups ? geotabGroups[0].toString() : '');
    }

    public configureApplicationForIntegratedMode(onDestroyCallback: () => void): void {
        (window as any).geotab.addin.video_platform = () => {
            return {
                initialize: (api: any, state: any, callback: any) => {
                    this.saveGeotabApi(api);
                    if (callback) {
                        callback();
                    }
                    window.onpopstate = (event) => {
                        this.validateGeotabGroupsFilter();
                    };
                },
                focus: (api: any, state: any) => {
                    this.saveGeotabApi(api, 'onFocus');
                    this.validateGeotabGroupsFilter();

                    //ensure that css is enabled
                    if (GeotabManager.getCssElements().length === 0) {
                        GeotabManager.restoreCssElements(() => {
                            IntegrationPartnerDataService.setCanRender(true);
                        });
                    } else {
                        //set can render to true if the file already exists to handle case where the data service got out of sync due to multiple instances
                        IntegrationPartnerDataService.setCanRender(true);
                    }
                },
                blur: (api: any, state: any) => {
                    this.clearGeotabApi();

                    if (!location.hash.includes('addin-videoprotects') && !location.hash.includes('addin-vidlocate')) {
                        //disable videoprotect styles when plugin loses focus and the new route is not a videoprotects page
                        IntegrationPartnerDataService.setCanRender(false);
                        GeotabManager.removeCssElements();
                    }

                    if (onDestroyCallback) {
                        onDestroyCallback();
                    }
                },
            };
        };
    }

    public configureApplicationForStandAloneMode(): void {
        // Currently there isn't anything to configure for the stand-alone geotab
    }

    public async handleCredentialExpiration(): Promise<void> {
        // Nothing to do in Geotab to handle credential expiration at the moment
    }

    public async tryLogin(): Promise<void> {
        if (ConfigService.isRunningStandAlone() && !location.pathname.includes('/login')) {
            // If we are running geotab stand alone and not on the login page then we need to create the api object
            this.trySetGeotabApi();
        }
    }

    public logout(): void {
        this.clearGeotabApi();
        this.redirectToLogin();
    }

    public getMapUrl(data: VideoEventResponse | undefined): any {
        if (!data) {
            return undefined;
        }

        const geotabUserCreds = GeotabManager.getGeotabUserCredentials();

        const sessionId = geotabUserCreds ? geotabUserCreds.SessionId : '';
        const username = geotabUserCreds ? geotabUserCreds.Name : '';
        const database = geotabUserCreds ? geotabUserCreds.Database : '';

        const server = geotabUserCreds?.Server ? `https://${geotabUserCreds.Server}` : location.origin;
        const credentials = '#credentials:(database:' + database + ",sessionId:'" + sessionId + "',userName:'" + username + "')";

        // small window - if trip start date is not within, the trip history / activity will be empty
        const mapEndDate = moment.utc(data.eventEndDate).add(15, 'minutes').toISOString();
        const mapStartDate = moment.utc(data.eventStartDate).subtract(15, 'minutes').toISOString();

        const mapUrl =
            server +
            '/' +
            database +
            '/#tripsHistory,' +
            credentials +
            ",dateRange:(endDate:'" +
            mapEndDate +
            "',startDate:'" +
            mapStartDate +
            "'),devices:!(" +
            data.telematicDeviceSourceId +
            ')';

        return mapUrl;
    }

    public getLinkUrl(standaloneUrl: string): string {
        if (ConfigService.isRunningStandAlone()) {
            return standaloneUrl;
        } else {
            let integratedHash;
            switch (standaloneUrl.toLowerCase()) {
                case '/videorecall':
                    integratedHash = '#addin-vidlocate-VideoRecall';
                    break;
                case '/deviceassociation':
                    integratedHash = '#addin-vidlocate-DeviceAssociations';
                    break;
                case '/installers':
                    integratedHash = '#addin-vidlocate-Installers';
                    break;
                case '/settings':
                    integratedHash = '#addin-vidlocate-Settings';
                    break;
                case '/videodashboard':
                    integratedHash = '#addin-vidlocate-VideoDashboard';
                    break;
                case '/viewagreements':
                    integratedHash = '#addin-vidlocate-ViewAgreements';
                    break;
                case '/':
                case '/videoevents':
                default:
                    integratedHash = '#addin-vidlocate-VideoEvents';
                    break;
            }

            let newIntegratedHash = integratedHash;
            if (location.hash.includes('videoprotects')) {
                newIntegratedHash = integratedHash.replace('vidlocate', 'videoprotects');
            }

            return '/' + GeotabManager.getGeotabUserCredentialsDatabase() + newIntegratedHash;
        }
    }

    public postAuthentication(UserName: string, ServerName: string, DatabaseName: string, Password: string, redirect: string) {
        const geotabApi: any = GeotabApi(
            (authenticationCallback: any) => {
                authenticationCallback(ServerName, DatabaseName, UserName, Password, (errorString: string) => {
                    console.error('GeotabManager.postAuthentication(...) Callback Error: ' + errorString);
                });
            },
            {
                // Override the default of true, true will continue to use the session, server, db, user stored in local storage and not the supplied credentials
                // In our scenario this results in potentially creating an api object for something other than what the user entered in our login form
                // https://github.com/Geotab/mg-api-js
                rememberMe: true,
            },
        );

        // Validate we are authenticated
        // Save API Object and Send to Backend for use on API
        geotabApi.getSession((session: any) => {
            if (redirect === window.location.pathname) {
                redirect = '/videoevents';
            }
            // This is ok because we are only re-routing in stand alone
            window.location.href = redirect;
        });
    }

    private saveGeotabApi(geotabApi: any, caller: 'onFocus' | 'default' = 'default') {
        this.geotabApiObject = geotabApi;

        this.geotabApiObject.getSession((session: any) => {
            if (caller === 'onFocus' && !this.isInitialized) {
                return;
            }
            this.isInitialized = true;

            if (session != undefined) {
                const geotabUserCredentialString = JSON.stringify({
                    Name: session.userName,
                    SessionId: session.sessionId,
                    Database: session.database,
                    Server: 'my.geotab.com',
                });

                UserManagementService.setUserCredential(geotabUserCredentialString);
            } else {
                console.error('GeotabManager.saveGeotabApi(...):  Failed to acquire session information from the Geotab Api object.');
                this.redirectToLogin();
            }
        });
    }

    private trySetGeotabApi() {
        // Validate the api object...(if it's not already authenticated from GeotabApi session storage, managed by their api object, it will call the callback)
        const api: any = GeotabApi((authenticationCallback: any) => {
            // Note: The authentication callback needs a password, we don't have that except when created from the login form
            // Because our login form is a separate page we can't pass the api object created from the login page via the 'postAuthentication' method in this freezer
            // So we must first try to create the api object which will use what's in the browsers local storage. If local storage is empty it will call the authentication callback
            // ...which will fail and redirect to login or error page
            authenticationCallback('', '', '', '', (errorString: string) => {
                console.error('GeotabManager.trySetGeotabApi() Callback Error: ' + errorString);
                this.redirectToLogin();
            });
        });
        this.saveGeotabApi(api);
    }

    private clearGeotabApi() {
        if (ConfigService.isRunningStandAlone()) {
            // api.forget() removes the local storage values that the api object uses to set credentials (let geotab manage this in integrated mode)
            this.geotabApiObject.forget();
        }

        // Clear our api object from freezer
        this.geotabApiObject = null;
    }

    private redirectToLogin(): void {
        // If we're integrated into the GeoTab portal, we do NOT want to navigate to the login page (only for stand-alone)
        if (ConfigService.isRunningStandAlone()) {
            location.href = '/login';
        }
    }

    public static isIntegrated(): boolean {
        return (window as any).geotab !== undefined;
    }

    public static getGeotabUserCredentialsDatabase(): string {
        const creds = GeotabManager.getGeotabUserCredentials();
        return creds ? creds.Database : '';
    }

    private static getGeotabUserCredentials(): GeotabUserCredentials {
        return JSON.parse(UserManagementService.getUserCredential());
    }

    public static getGeotabBaseUrl(baseUrl: string, apiBaseUrl: string): string {
        let url = location.origin;
        // chaeck to see if the base configuration is set already (this works when loaded directly and not from the mygeotab intergration) //
        // and if not, use the ServiceConfig.EntryPoint environment variable, set on the docker container or from VS debugger //
        // by using an intercept controller that replaces this dummy string with the correct value //
        // NOTE: this is truely a string equaliing "undefined" and is not a typo/bug //
        if (baseUrl !== 'undefined' || apiBaseUrl === undefined) {
            // Hacky, i know but we need to get the original domain for images and such, so this is attempting to find it on an injected script.
            //.querySelector will return the first match of the comma delimited list of selectors(we should only have one present)
            const scriptelement = document.querySelector(
                "script[src*='yaharasoftware.com'], script[src*='videoprotects.com'], script[src*='videoprotects.net'], script[src*='jjk-videoprotects.com']",
            );
            const src = scriptelement !== null ? scriptelement.getAttribute('src') : '';
            const urlObject = GeotabManager.parseURL(src);
            url = 'https://' + urlObject.hostname + '/';
        }
        return url;
    }

    private static parseURL(url: string | null | undefined): any {
        const parser = document.createElement('a');
        // Let the browser do the work
        parser.href = url === null || url === undefined ? '' : url;
        return {
            protocol: parser.protocol,
            host: parser.host,
            hostname: parser.hostname,
            port: parser.port,
            pathname: parser.pathname,
            search: parser.search,
            hash: parser.hash,
        };
    }

    private static getCssElements(): NodeListOf<Element> {
        return document.querySelectorAll(`head link[href='${ConfigService.getBaseUrl()}/bundle.css']`);
    }

    private static removeCssElements() {
        GeotabManager.getCssElements().forEach((element) => {
            element.remove();
        });
    }

    private static restoreCssElements(onLoadListener: (this: HTMLLinkElement, ev: Event) => any) {
        var newCssElement = document.createElement('link');
        newCssElement.href = `${ConfigService.getBaseUrl()}/bundle.css`;
        newCssElement.setAttribute('rel', 'stylesheet');
        newCssElement.addEventListener('load', onLoadListener, { once: true });
        document.head.appendChild(newCssElement);
    }
}
